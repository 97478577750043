
import { computed, defineComponent, onMounted, ref } from "vue";
import Entities from "../../components/base/common/Entities.vue";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { LoadFilterObjects } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import RecentCards from "@/components/base/common/RecentCards.vue";
import CaseCard from "@/views/case/CaseCard.vue";

export default defineComponent({
  name: "Watching",
  components: {CaseCard, RecentCards, Entities},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Watching Cases',
        [
          {link: false, router: '', text: 'Watching Cases'}
        ]
      )
    })
    const filterObject = ref({watching: true})
    const page = computed(() => store.state.CaseModule.watchingCases)
    return {
      page,
      ...LoadFilterObjects(Actions.LOAD_WATCHING_CASES, filterObject.value, ['client', 'insured', 'policy'])
    }
  }
})
